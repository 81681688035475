@import './vars.module.scss';

.cardPlaceholder {
  border: solid 1px $medium-gray;
  background-color: $white;
  padding: 25px 24px 36px;
  @media screen and (min-width: 768px) {
    display: grid;
    padding: 24px 24px 21px;
    grid-template-areas: "title title title title button"
                 "likesmatchtags likesmatchtags likesmatchtags likesmatchtags range";
  }
  &:not(:last-child) {
    margin-bottom: 40px;
  }
  position: relative;
}
.title {
  grid-area: title;
  height: 22px;
  background-color: $light-gray;
}
.buttonContainer {
  grid-area: button;
  display: flex;
  justify-content: flex-end;
  @media screen and (max-width: 767px) {
    margin-top: 20px;
  }
}
.button {
  display: inline-block;
  box-sizing: border-box;
  background-color: $light-gray;
  display: flex;
  justify-content: flex-end;
  align-items: center;
  position: relative;
  height: 32px;
  width: 107px;
  padding-right: 16px;
  @media screen and (min-width: 768px) {
    top: 0;
  }
}
.arrowRight {
  width: 15px;
  position: relative;
  top: 1px;
}
.thumbs {
  display: inline-block;
  margin-top: 20px;
}
.thumbsContainer {
  padding: 0 6px;
  position: relative;
  padding-right: 16px;

  &:first-child {
    padding-left: 0;
    padding-right: 6px;
  }
}
.thumbsButton {
  width: 20px;
  height: 18px;
}
.likesMatchTags {
  @media screen and (min-width: 768px) {
    align-items: center;
    grid-area: likesmatchtags;
    display: flex;
  }
}
.tag {
  display: inline-block;
  background-color: $light-gray;
  margin-top: 20px;
  height: 22px;
  width: 107px;
  &:not(:last-child) {
    margin-right: 8px;
  }
}
.range {
  grid-area: range;
  display: flex;
  justify-content: flex-end;
  position: relative;
  margin-top: 20px;
}
.rangeValue {
  background-color: $light-gray;
  height: 22px;
  width: 100%;
}

@keyframes placeHolderShimmer{
  0%{
      background-position: -468px 0
  }
  100%{
      background-position: 468px 0
  }
}

.animatedBackground {
  animation-duration: 1.25s;
  animation-fill-mode: forwards;
  animation-iteration-count: infinite;
  animation-name: placeHolderShimmer;
  animation-timing-function: linear;
  background: darkgray;
  background: linear-gradient(to right, $light-gray 10%, darken($light-gray, 5%) 18%, $light-gray 33%);
  background-size: 800px 104px;
  position: relative;
}