@import './vars.module.scss';

.autocompleteContainer {
  display: inline-block;
  width: 100%;

  @media screen and (min-width: 768px) {
    width: 203px;
  }
}
.root {
  position: relative;
}
.input {
  box-sizing: border-box;
  border: solid 1px $medium-gray;
  border-radius: 0;
  height: 40px;
  width: 100%;
  padding: 12px;
  font-size: 12px;
  position: relative;
  left: -1px;
  &:focus {
    outline: none;
  }
  &::placeholder {
    /* Chrome, Firefox, Opera, Safari 10.1+ */
    color: $dark-gray;
    opacity: 1; /* Firefox */
  }
  &:-ms-input-placeholder {
    /* Internet Explorer 10-11 */
    color: $dark-gray;
  }
  &::-ms-input-placeholder {
    /* Microsoft Edge */
    color: $dark-gray;
  }
}
.focused {
  @media screen and (max-width: 767px) {
    height: 201px;
  }
}
.ratingsSortingFocused {
  @media screen and (max-width: 767px) {
    height: 155px;
  }
}
.ratingsFiltersFocused {
  @media screen and (max-width: 767px) {
    height: 251px;
  }
}
.inputFocused {
  border-radius: 0;
}
.checkboxRoot {
  color: $black !important;
}
.checkboxLabel {
  color: $dark-gray;
  font-size: 12px;
  line-height: 18px;
  white-space: break-spaces;
}
.paper {
  margin: 0 !important;
  border-radius: 0 !important;
  border-left: solid 1px $medium-gray;
  border-right: solid 1px $medium-gray;
  border-bottom: solid 1px $medium-gray;
}
.option {
  padding: 0 5px;
}
.arrowDown {
  width: 15px;
  position: absolute;
  top: 18px;
  right: 14px;
  z-index: 2;
  pointer-events: none;
  &:hover {
    cursor: pointer;
  }
}
.tags {
  display: block;
  h5 {
    display: inline-block;
  }
  div {
    display: inline-block;
    margin-right: 8px;

    &:first-of-type {
      margin-left: 14px;
    }
  }
  @media screen and (max-width: 767px) {
    text-align: center;
  }
}
.clearAll {
  position: relative;
  top: 2px;
  padding-left: 8px;
  @media screen and (max-width: 767px) {
    display: block;
    text-align: center;
  }
}
.addFilter {
  position: relative;
  padding-left: 8px;
  @media screen and (max-width: 767px) {
    display: block;
    text-align: left;
  }
}
.selectRoot {
  box-sizing: border-box;
  border: solid 1px $medium-gray !important;
  border-radius: 0;
  height: 40px;
  width: 100%;
  padding: 12px;
  font-size: 12px;
  left: -1px;
  &:focus {
    outline: none;
  }
  &::placeholder {
    /* Chrome, Firefox, Opera, Safari 10.1+ */
    color: $dark-gray;
    opacity: 1; /* Firefox */
  }
  &:-ms-input-placeholder {
    /* Internet Explorer 10-11 */
    color: $dark-gray;
  }
  &::-ms-input-placeholder {
    /* Microsoft Edge */
    color: $dark-gray;
  }
  &__tall {
    height: 56px;
  }
  &__MobileTall {
    @media screen and (max-width: 767px) {
      height: 56px;
    }
  }
}
.selectSelect {
  box-sizing: border-box !important;
  width: 100% !important;
  height: 40px !important;
  &__tall {
    height: 56px !important;
  }
  &__MobileTall {
    @media screen and (max-width: 767px) {
      height: 58px !important;
    }
  }
}
.formControl {
  width: 100% !important;
  position: relative;
  margin-bottom: 0 !important;

  @media screen and (min-width: 768px) {
    width: 203px !important;
  }
}
.languagesFormControl {
  width: 100% !important;
  position: relative;
  margin-bottom: 0 !important;

  @media screen and (min-width: 768px) {
    width: 216px !important;
  }
}
.industriesSelect {
  width: 100% !important;
  position: relative;
  margin-bottom: 0 !important;
}
.selectLabel {
  position: absolute;
  z-index: 1;
  font-size: 12px;
  color: $dark-gray;
  left: 13px;
  top: 13px;
  pointer-events: none;
  @media screen and (max-width: 767px) {
    font-size: 16px;
  }
}
.selectMenu {
  box-sizing: border-box;
  width: calc(100% - 92px) !important;
  border-radius: 0 !important;
  border-left: solid 1px $medium-gray;
  border-right: solid 1px $medium-gray;
  border-bottom: solid 1px $medium-gray;

  @media screen and (min-width: 768px) {
    width: 203px !important;
  }
}
