@import './vars.module.scss';

.card {
  border-left: 3px solid #0E6E10;
  padding: 24px 32px 28px 24px;
  background-color: $white;
  display: flex;
  flex-direction: column;
  justify-content: space-between;

  &__title {
    color: $black;
    font-size: 16px;
    font-weight: 700;
  }
  &:hover {
    cursor: pointer;
  }
}
.cardYellow {
  border-left: 3px solid #FCCB00;
}
.notClickable {
  cursor: default;
  &:hover {
    cursor: default;
  }
}
.icon {
  height: 20px;
  width: 20px;
  background-color: #0E6E10;
  border-radius: 50%;
  color: $white;
  display: inline-flex;
  justify-content: center;
  align-items: center;
  margin-right: 6px;
}
.addNewIcon {
  background-color: #FCCB00;
}
.checkIcon {
  font-size: 17px !important;
}
.addIcon {
  font-size: 20px !important;
}
.iconSection {
  display: flex;
  padding-bottom: 15px;
  text-transform: uppercase;
  font-size: 16px;
  color: $dark-gray;
  align-items: center;
}
.dateSection {
  margin-top: 15px;
  font-size: 12px;
  color: $dark-gray;
}
.addActivityLink {
  font-size: 16px;
}