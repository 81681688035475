@import './vars.module.scss';

.layout {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  min-height: 100vh;
}
.header {
  height: 64px;
  background-color: $white;
  display: flex;
  align-items: center;
}
.headerContent {
  margin: 0 !important;
  display: flex;
  align-items: center;
  position: relative;
}
.header__blueBar {
  background-color: $navy;
  height: 24px;
}
.logoContainer {
  flex-grow: 1;
}
.desktopCentered {
  display: flex;
  @media screen and (min-width: 1024px) {
    justify-content: center;
  }
}
.headerLogo {
  padding-top: 4px;
  height: $logo-header-height;
}
.headerSecondLogo {
  padding-top: 4px;
  height: 40px;
  @media screen and (max-width: 1023px) {
    height: 32px;
  }
}
.header__alt {
  height: 90px;
  z-index: 10;

  @media screen and (max-width: 767px) {
    height: 60px;
    position: sticky;
    top: 0;
  }
  &__mobileMenuOpen {
    @media screen and (max-width: 767px) {
      width: 100%;
      position: fixed;
      top: 0;
      z-index: 3;
    }
  }
}
.newHeroLanguagesDesktop {
  @media screen and (max-width: 959px) {
    display: none;
  }
}
.footer {
  background-color: $navy;
  color: $white;
  padding-bottom: 11px;
  position: relative;
}
.footerContent {
  margin: 0 !important;
  padding-top: 20px;
  padding-bottom: 14px;
}
.footerContentBottom {
  margin: 0 !important;
  padding-top: 8px;
  padding-bottom: 8px;
}
.footerImages {
  height: 70px;
  @media screen and (max-width: 599px) {
    display: flex;
    justify-content: center;
  }
}
.footerText {
  font-size: 14px;
  line-height: 24px;
  max-width: 370px;
  margin: 0 auto;
  color: #fff !important;
  a {
    color: #fff !important;
  }
  @media screen and (max-width: 599px) {
    margin: 0 auto;
    text-align: center;
  }
}
.officialText {
  padding-top: 9px;
  margin: 0;
  @media screen and (max-width: 599px) {
    padding-bottom: 18px;
    text-align: center;
    margin: 0 auto;
  }
}
.footerLogo {
  height: 33px;
  height: $logo-footer-height;
  &:nth-child(odd) {
    padding-right: 10px;
  }
}
.footerCopy {
  font-size: 12px;
  padding: 5px 0 2px;
  max-width: 370px;
  margin: 0 auto;
  @media screen and (max-width: 599px) {
    text-align: center;
  }
}
.separator {
  width: 100%;
  border-top: solid 1px $medium-gray;
  border-left: none;
  border-right: none;
  border-bottom: none;
  margin-top: 3px;
  margin-bottom: 8px;
}
.separatorBold {
  width: 100%;
  border-top: solid 3px $light-gray;
  border-left: none;
  border-right: none;
  border-bottom: none;
  margin: 0;
}
.colorsBar {
  position: relative;
  display: flex;
}
.colorsBar__color {
  height: 6px;
  width: 25%;
}
.colorsBar__red {
  background-color: $red;
}
.colorsBar__green {
  background-color: $green;
}
.colorsBar__orange {
  background-color: $orange;
}
.colorsBar__blue {
  background-color: $blue;
}
.link,
.link:visited {
  color: $action-blue !important;
  font-weight: 700;
  text-decoration: underline;
}
.link:hover,
.link:active {
  color: $action-blue-hover;
  cursor: pointer;
}
.linkWhite,
.linkWhite:visited {
  color: $white;
  font-weight: 700;
  text-decoration: underline;
}
.linkWhite:hover,
.linkWhite:active {
  color: darken($white, 10%);
  cursor: pointer;
}
.bold {
  font-weight: 700;
}
.descriptor {
  font-size: 14px;
  line-height: 21px;
  color: $dark-gray;
}
.descriptorBold {
  font-size: 14px;
  line-height: 21px;
  color: $dark-gray;
  font-weight: 700;
}
.formCoachingText {
  font-size: 12px;
  line-height: 18px;
  color: $dark-gray;
}
.infoIcon {
  width: 20px;
  height: 20px;
}
.infoIcon__container {
  padding-left: 9px;
}
.hidden {
  display: none;
}
.searchInputRoot {
  margin-bottom: 0 !important;
  background-color: $white !important;
  transition: all 0.35s;
  svg {
    fill: $primary-color-main;
    font-size: 26px;
  }
  input {
    padding: 12px 0 13px 0 !important;
    background-color: $white;
    width: 0;
    transition: all 0.35s;
    &:focus {
      padding: 12px 11px 13px !important;
      width: 200px;
      background-color: $light-gray;
    }
    @media screen and (max-width: 767px) {
      padding: 11px 11px 9px !important;
    }
  }
  @media screen and (max-width: 767px) {
    display: none !important;
  }
}
.inputFocused {
  background-color: $light-gray !important;
  margin-left: 11px;
}
.searchFieldLabel {
  cursor: pointer;
}
.mobileSearchInputRoot {
  margin-bottom: 0 !important;
  background-color: $white !important;
  transition: all 0.35s;
  position: absolute !important;
  left: -20px;
  z-index: 3;
  svg {
    fill: $primary-color-main;
    font-size: 26px;
  }
  input {
    padding: 0 !important;
    background-color: $white;
    /*width: 0;*/
    transition: all 0.35s;
    position: absolute;

    &:focus {
      padding: 12px 32px 13px !important;
      width: calc(100vw - 94px);
      background-color: $light-gray;
      @media screen and (max-width: 599px) {
        width: calc(100vw - 79px);
      }
    }
  }
  @media screen and (min-width: 768px) {
    display: none !important;
  }
}
.mobileSearchButton {
  z-index: 1000;
  @media screen and (min-width: 768px) {
    display: none !important;
  }
}
.headerLeftCell {
  @media screen and (max-width: 767px) {
    position: relative;
    left: -16px;
  }
}
.browserBanner {
  display: flex;
  justify-content: center;
  text-align: center;
  padding: 12px 16px;
  background-color: $secondary-color-main;
}
.envBanner {
  display: flex;
  justify-content: center;
  text-align: center;
  padding: 10px 16px;
  background-color: #e51f1f;
  color: $white;
  font-size: 14px;
}
.hamburgerButton {
  &__container {
    display: flex;
    flex-direction: column;
    position: relative;
    top: 4px;
  }
  &__text {
    color: $action-blue;
    text-transform: uppercase;
    font-size: 9px;
    font-weight: 500;
    text-align: center;
    padding-top: 5px;
  }
  &:hover {
    cursor: pointer;
  }
}

.mobileMenu {
  position: absolute;
  top: 60px;
  left: 0;
  z-index: 100;
  background: $navy;
  width: 100vw;
  opacity: 0;
  pointer-events: none;
  transition: all 0.5s;

  &__link {
    font-weight: 400;
    font-size: 14px;
    text-transform: uppercase;
  }
  &__languagesContainer {
    border-bottom: solid 1px $action-blue;
    padding: 16px;
  }
}
.mobileMenu ul {
  padding: 14px 16px 20px;
  margin: 0;
}
.mobileMenu__item {
  list-style: none;
  padding: 8px 0;
  border-bottom: 1px solid rgba($navy, 0.4);
}
.mobileMenu__item a {
  text-transform: uppercase;
  font-size: 22px;
  color: $white;
  font-weight: 600;
  display: flex;
  align-items: center;
  text-decoration: none;
}
.mobileMenu__item.noTextTransform a {
  text-transform: none !important;
}
.mobileMenu__item i {
  padding-right: 10px;
}
.mobileMenuOpen {
  position: fixed;
  opacity: 1;
  left: 0;
  overflow: scroll;
  pointer-events: all;
}
.creditsButton {
  padding: 12px;
  text-align: center;
}
.closeButton {
  float: right;
  position: relative;
  top: 0;
  padding: 40px;
}
.topMobile {
  @media screen and (max-width: 767px) {
    top: 4px !important;
    margin-left: 20px !important;
  }
  @media screen and (min-width: 768px) {
    margin-left: 10px !important;
  }
}
.bellIcon {
  color: $secondary-color-dark;
  position: relative;
  right: 2px;
  height: 24px !important;
  width: 24px !important;

  &__container {
    position: relative;
    top: 5px;
    width: 24px;
    height: 26.3px;
  }
  &__number {
    color: $black;
    position: absolute;
    top: -2px;
    left: 7px;
    font-size: 10px;
    font-weight: 600;
  }
}
