$organization-name: 'Hawaii Department of Labor and Industrial Relations';
$footer-email: 'DLIR.Workforce.Develop@hawaii.gov';
$logo-header-url: '/images/logo-header-hi.jpeg';
$logo-header-height: 51px;
$logo-footer-url: '/images/logo-footer-hi.webp';
$logo-footer-height: 70px;
$font-family: 'Open Sans', sans-serif;
$primary-color-main: #2E6AD1;
$primary-color-dark: #234C9F;
$navy: #003871;
$accent-color-main: #ffc709;
$secondary-color-main: #FFEF74;
$secondary-color-dark: #f1d819;
$error-color-main: #C90000;
$background-color-default: #efefef;
$background-color-paper: #fff;
$dark-gray: #424242;
$mid-light-gray: #DBDBDB;
$medium-gray: #B7B7B7;
$light-gray: #F5F5F5;
$black: #000;
$white: #FFF;
$red: #EB5152;
$dark-red: #CB3130;
$light-red: #FCE6E6;
$green: #77CC66;
$dark-green: #60A752;
$light-green: #EBF8E8;
$progress-green: #129015;
$orange: #EB843B;
$dark-orange: #D07434;
$blue: #8FB9E2;
$dark-blue: #5994CF;
$action-blue: #2E6AD1;
$action-blue-hover: #234C9F;
$tag-skill: lighten($blue, 18%);
$filter-tag: $white;
$filter-tag-hover: darken($white, 4%);

//mui breakpoints
$breakpoint-xs: 0px;
$breakpoint-sm: 600px;
$breakpoint-md: 960px;
$breakpoint-lg: 1280px;
$breakpoint-xl: 1920px;

$second-logo-header-url: '/images/logo-hican.svg';
$state-seal-url: '/images/state-seal-hi.png';
$state-seal-href: 'https://portal.ehawaii.gov/';
$header-logo-href: 'https://labor.hawaii.gov/';

:export {
  organizationName: $organization-name;
  footerEmail: $footer-email;
  logoHeaderUrl: $logo-header-url;
  logoFooterUrl: $logo-footer-url;
  fontFamily: $font-family;
  primaryColorMain: $primary-color-main;
  primaryColorDark: $primary-color-dark;
  secondaryColorMain: $secondary-color-main;
  secondaryColorDark: $secondary-color-dark;
  accentColorMain: $accent-color-main;
  errorColorMain: $error-color-main;
  backgroundColorDefault: $background-color-default;
  backgroundColorPaper: $background-color-paper;
  darkGray: $dark-gray;
  mediumGray: $medium-gray;
  midLightGray: $mid-light-gray;
  lightGray: $light-gray;
  black: $black;
  white: $white;
  navy: $navy;
  red: $red;
  darkRed: $dark-red;
  lightRed: $light-red;
  green: $green;
  lightGreen: $light-green;
  darkGreen: $dark-green;
  progressGreen: $progress-green;
  orange: $orange;
  darkOrange: $dark-orange;
  blue: $blue;
  darkBlue: $dark-blue;
  cardAccentExperience: $blue;
  actionBlue: $action-blue;
  actionBlueHover: $action-blue-hover;
  tagBackgroundColor: $white;
  tagCareer: $light-red;
  tagJob: $light-green;
  tagJobHover: darken($light-green, 4%);
  tagSkill: $tag-skill;
  tagSkillHover: lighten($blue, 12%);
  filterTag: $filter-tag;
  filterTagHover: $filter-tag-hover;
  secondLogoHeaderUrl: $second-logo-header-url;
  stateSealUrl: $state-seal-url;
  stateSealHref: $state-seal-href;
  headerLogoHref: $header-logo-href;

  // Recommendations Card
  cardAccentCareer: $red;
  cardAccentJob: $green;
  cardAccentTraining: $orange;

  // Chat
  chatTitleAvatar: '/images/plumeria.png';
  chatProfileAvatar: '/images/bot-profile.png';
  chatLogo: "/images/bot-profile.png";
}