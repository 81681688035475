@import './vars.module.scss';

.top {
  display: grid;
  grid-template-columns: 1fr;
  @media screen and (min-width: 960px) {
    grid-template-columns: 220px 1fr;
  }
}
.imageSection {
  background-color: $navy;
  display: grid;
  grid-template-columns: 30% 70%;
  min-height: 91px;
  
  @media screen and (min-width: 960px) {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    min-height: auto;
  }
}
.imageWrapper {
  @media screen and (max-width: 959px) {
    display: flex;
    justify-content: center;
    align-items: center;
  }
}
.imageDescription {
  padding: 13px 23px;
  color: $white;
  font-size: 14px;
  text-align: center;
  @media screen and (max-width: 959px) {
    display: flex;
    justify-content: flex-start;
    align-items: center;
    padding: 0 58px 0 0;
    text-align: left;
  }
}
.textSection {
  display: flex;
  flex-direction: column;
  padding: 40px 30px;
  background-color: $white;
  position: relative;
}
.iconButton {
  position: absolute;
  top: 20px;
  right: 20px;
  &:hover {
    cursor: pointer;
  }
}
.addIcon {
  color: $action-blue;
  font-size: 37px !important;
}
.toQualifyTitle {
  font-size: 16px;
  color: $black;
  font-weight: 700;
}
.toQualifyItems {
  list-style-image: url('/assets/images/Circle.svg');
  li p {
    margin-bottom: 0;
  }
}
.learnMore {
  background-color: $white;
  box-sizing: border-box;
  max-height: 0;
  transition: all .4s ease-in;
  overflow: hidden;
  position: relative;
  padding: 0 26px 0 21px;
  border-top: solid 1px transparent;
  @media screen and (min-width: 960px) {
    padding: 0 73px 0;
  }
}
.learnMore.open {
  max-height: 1000px;
  border-top: solid 1px $medium-gray;
  padding: 38px 26px 39px 21px;
  @media screen and (min-width: 960px) {
    padding: 38px 73px 39px;
  }
}
.noMargin {
  margin: 0;
}