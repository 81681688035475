@import './vars.module.scss';

.menuDrawer {
  width: 250px;
  flex-shrink: 0;
}
.menuDrawerPaper {
  width: 250px;
}
.menuDrawerCloseContainer {
  margin-left: 10px !important;
  margin-top: 10px !important;
  background-color: lighten($navy, 8%) !important;
  color: $white !important;
  border-radius: 4px !important;
  padding: 5px !important;

  &:hover {
    background: lighten($navy, 16%) !important;
  }
}
.menuDrawerClose {
  font-size: 20px;
  position: relative;
  left: 2px;
  top: 2px;
}
.menuDrawerLink {
  font-weight: 600;
  text-transform: uppercase;
  text-decoration: none;
  color: $black;
  padding: 4px 0;
  font-size: 14px;
  
  & span {
    font-size: 14px;
  }

  & .MuiListItemText-root {
    font-weight: 600;
  }

  &:hover {
    cursor: pointer;
  }
}
.menuDrawerLink__text {
  font-weight: 400;
}
.menuDrawerLink__text__link {
  color: $white;
  &:hover {
    cursor: pointer;
  }
}