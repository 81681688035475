@import './vars.module.scss';

.editJobContainer {
  position: relative;
  top: 19px;
}
.editJobContact {
  position: absolute;
  background-color: $white;
  width: 100%;
  min-height: 153px;
  z-index: 3;

  box-sizing: border-box;
  opacity: 0;
  transition: opacity 0.3s;
}
.topSection {
  display: flex;
  flex-direction: column;
  padding: 26px 30px 32px;
}
.formSection {
  display: flex;
  flex-direction: column;
  padding: 26px 30px 32px;
  background-color: $white;
  padding-top: 196px;
  position: relative;
  z-index: 2;
}
.editJobContact.opaque {
  opacity: 1;
  position: absolute;
}
.close {
  color: $action-blue;
  position: absolute;
  right: 20px;
  top: 20px;
  &:hover {
    cursor: pointer;
  }
}
.hidden {
  //display: none;
  pointer-events: none;
}
.title {
  font-size: 16px;
  font-weight: 700;
  color: $black;
  padding-bottom: 19px;
}
.label {
  padding-bottom: 5px;
}
.formControl {
  width: 100% !important;
  position: relative;
  margin-bottom: 0 !important;

  @media screen and (min-width: 768px) {
    width: 408px !important;
  }
}
.cardsContainer {
  display: grid;
  grid-gap: 16px;
  grid-template-columns: 1fr;
  z-index: 1;
  @media screen and (min-width: 768px) {
    grid-template-columns: 1fr 1fr 1fr;
  }
  @media screen and (min-width: 1280px) {
    grid-template-columns: 1fr 1fr 1fr 1fr;
  }
}
.absolute {
  position: absolute;
  top: 0;
}
