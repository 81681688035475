@import './vars.module.scss';

.likesFilters {
  display: flex;
  margin: 9px 0 16px;
  flex-direction: column;
  align-items: center;
  @media screen and (min-width: 768px) {
    flex-direction: row;
    justify-content: space-between;
  }
}
.thumbsImage {
  width: 22px;
  height: 20px;
  position: relative;
  top: 2px;
}
.thumbsButton {
  cursor: pointer;
  background-color: $white;
  display: inline-block;
  position: relative;
  padding: 10px 13px;
  border: solid 1px $medium-gray;
  margin-bottom: 4px;

  &:nth-child(2) {
    border-left: none;
  }

  .filledThumbs {
    display: none;
  }
  .defaultThumbs {
    display: inline;
  }
  &:hover {
    .defaultThumbs {
      display: none;
    }
    .filledThumbs {
      display: inline;
    }
  }
  &:active {
    .filledThumbs {
      transform: scale(0.95);
    }
  }
}
.emptyStateContainer {
  max-width: 520px;
  margin: 0 auto;
}
.filters {
  margin-top: 16px;
  & > div:last-child {
    margin-top: 16px;
  }
  @media screen and (max-width: 767px) {
    width: 100%;
  }
  @media screen and (min-width: 768px) {
    margin-top: 0;

    & > div:last-child {
      margin-top: 0;
    }
  }
}
