@import './vars.module.scss';

.card {
  color: $white;
  position: relative;
}
.top {
  display: grid;
  grid-template-columns: 1fr;
  @media screen and (min-width: 960px) {
    grid-template-columns: 150px 1fr;
  }
}
.imageSection {
  background-color: $navy;
  display: grid;
  grid-template-columns: 30% 70%;
  min-height: 91px;
  
  @media screen and (min-width: 960px) {
    display: flex;
    flex-direction: column;
    padding-top: 28px;
    justify-content: flex-start;
    align-items: center;
    min-height: auto;
  }
}
.imageWrapper {
  @media screen and (max-width: 959px) {
    padding-left: 25px;
    padding-top: 28px;
  }
}
.imageDescription {
  padding: 13px 23px;
  color: $white;
  font-size: 14px;
  text-align: center;
  @media screen and (max-width: 959px) {
    display: flex;
    justify-content: flex-start;
    align-items: center;
    padding: 0 58px 0 0;
    text-align: left;
  }
}
.textSection {
  display: flex;
  flex-direction: column;
  padding: 25px 30px 30px 0;
  background-color: $navy;
  @media screen and (max-width: 959px) {
    padding: 25px 30px 30px 25px;
  }
}
.textInternalSection {
  display: flex;
  flex-direction: column;
  padding: 0 30px 30px 3px;
  background-color: $navy;
  position: relative;
}
.iconButton {
  position: absolute;
  top: 11px;
  right: 20px;
  &:hover {
    cursor: pointer;
  }
}
.toQualifyTitle {
  font-size: 16px;
  color: $white;
  font-weight: 700;
}
.toQualifyItems {
  list-style-image: url('/assets/images/Circle.svg');
  li p {
    margin-bottom: 0;
  }
}
.learnMore {
  background-color: $navy;
  box-sizing: border-box;
  max-height: 0;
  transition: padding-top .4s, padding-bottom .4s, max-height .4s;
  overflow: hidden;
  position: relative;
  padding: 0 26px 0 21px;
  @media screen and (min-width: 960px) {
    padding: 0 73px 0 150px;
  }
}
.learnMore.open {
  max-height: 10000px;
  padding: 0 26px 39px 21px;
  @media screen and (min-width: 960px) {
    padding: 0 73px 7px 150px;
  }
}
.showMoreContainer {
  max-height: 100px;
  transition: max-height .4s;
  span {
    max-height: 100px !important;
    opacity: 1;
    transition-delay: .4s;
    transition: all .4s;
  }
}
.drawerOpen {
  max-height: 0;
  span {
    max-height: 0 !important;
    opacity: 0;
    transition-delay: 0s;
  }
}
.noMargin {
  margin: 0;
}
.expanderSeparator {
  width: 100%;
  border-top: solid 1px $action-blue;
  border-left: none;
  border-right: none;
  border-bottom: none;
  margin-top: 0;
  margin-bottom: 20px;
}
.squares {
  display: grid;
  grid-template-columns: 1fr;
  @media screen and (min-width: 960px) {
    grid-template-columns: repeat(3, 1fr);
  }
}
.benefitCard {
  display: flex;
  flex-direction: column;
  .image {
    height: 70px;
    display: flex;
    align-items: center;

  }
}
.financialHelpButton {
  @media screen and (min-width: 581px) {
    margin-bottom: 10px !important;
  }
}