@import './vars.module.scss';

.header {
  background-color: $navy;
  padding: 36px 0 41px;
  position: relative;
  @media screen and (min-width: 600px) {
    padding: 36px 0;
  }
}
.header__title {
  font-size: 30px !important;
}
.header__content {
  background-color: $white;
  padding: 26px 14px 31px;
  border-left: solid 6px $green;
  border-right: solid 6px $red;
  position: relative;

  &:before {
    display: block;
    content: '';
    background: $orange;
    height: 6px;
    width: calc(100% + 12px);
    position: absolute;
    top: 0;
    left: -6px;
  }

  &:after {
    display: block;
    content: '';
    background: $blue;
    height: 6px;
    width: calc(100% + 12px);
    position: absolute;
    bottom: 0;
    left: -6px;
  }
}
.thumbs {
  width: 15px;
  padding: 0 3px;
}
.pencil {
  width: 14px;
  padding: 0 0 0 3px;
}
.chevron {
  width: 12px;
  padding: 0 0 0 4px;
}
.editLink {
  padding-left: 10px;
}
.experienceTag__container {
  display: flex;
  flex-direction: column;
  padding-top: 6px;

  @media screen and (min-width: $breakpoint-sm) {
    flex-direction: row;
    justify-content: center;
    margin-top: 0.25em;
  }

  @media screen and (max-width: 750px) {
    flex-direction: column;
    justify-content: center;
    margin-top: 0.25em;
  }

  .experienceTag {
    padding-top: 6px;
    font-size: 0.75rem;
    color: $dark-gray;
    font-weight: 700;
    text-transform: uppercase;
    padding: 0 10px;
    white-space: nowrap;
    display: inline-flex;
    align-items: flex-end;
    line-height: 18px;

    @media screen and (max-width: 750px) {
      margin: 0 auto;
    }

    & > span {
      &::after {
        content: '\00a0';
      }
    }

    .editLink {
      padding: 0;
      display: inline-flex;
      flex-direction: row;
      align-items: flex-end;
      text-transform: none;
      font-size: 1rem;
      color: $action-blue;

      &:hover {
        color: $action-blue-hover;
      }

      span {
        font-weight: bold;
      }

      svg {
        margin-left: 0.15em;
      }
    }
  }
}

.showButton {
  background-color: $action-blue;
  color: $white;
  display: inline-block;
  padding: 7px 18px 7px 21px;
  text-transform: uppercase;
  font-size: 12px;
  font-weight: 700;
  letter-spacing: 0.3px;
  position: absolute;
  bottom: 30px;
  left: 50%;
  transform: translateX(-50%);
  white-space: nowrap;

  &:hover {
    cursor: pointer;
  }

  @media screen and (min-width: 600px) {
    display: none;
  }
}
.accordionContent {
  overflow: hidden;
  transition: all 0.35s;
}
.accordionContent__visible {
  max-height: 100vh;
}
.accordionContent__hidden {
  max-height: 0;
}
.mainNav__itemsContainer {
  display: flex;
  justify-content: space-between;
  white-space: nowrap;
  overflow: auto;
  scroll-behavior: smooth;
  -ms-overflow-style: none; /* Hide scrollbar on IE and Edge */
  scrollbar-width: none; /* Hide scrollbar on Firefox */
  /* Hide scrollbar on Webkit based browsers */
  &::-webkit-scrollbar {
    display: none;
  }
  @media screen and (min-width: 800px) {
    justify-content: center;
  }
}
.mainNav {
  background-color: $white;
  height: 56px;
  border-bottom: solid 1px $medium-gray;
  overflow: hidden;
  position: relative;

  &__item {
    background-color: $white;
    text-transform: uppercase;
    font-weight: 600;
    display: flex;
    justify-content: center;
    align-items: center;
    height: 56px;
    padding: 0 20px;
    text-decoration: none;

    &:first-child {
      padding-left: 4px;
    }
    &:last-child {
      padding-right: 4px;
    }

    span {
      color: $action-blue;
      font-size: 14px;
      display: flex;
      height: 56px;
      align-items: center;
      box-sizing: border-box;
      background-color: $white;
      transition: all 0.35s;
      border-top: solid 3px $white;
      border-bottom: solid 3px $white;
    }

    &:hover {
      span {
        color: $black;
        cursor: pointer;
        border-bottom: solid 3px $black;
      }
    }

    &__active {
      span {
        color: $black;
        border-bottom: solid 3px $black;
      }
    }
  }
}
.chevronLink {
  display: flex;
  justify-content: center;
  height: 100%;
  width: 28px;
  background-color: $white;
  img {
    width: 8px;
  }
  @media screen and (min-width: 800px) {
    display: none;
  }
}
.chevronLinkLeft {
  position: absolute;
  top: 0;
  left: 0;
}
.chevronLinkRight {
  position: absolute;
  top: 0;
  right: 0;
}
.hidden {
  @media screen and (max-width: 799px) {
    display: none;
  }
}
.breakSpace {
  white-space: initial;
  text-align: center;
}
.extraButtons {
  margin-top: 17px;
  display: grid;
  grid-template-columns: 1fr 1fr;
  grid-gap: 4px;
  @media screen and (max-width: 599px) {
    display: none;
  }
}
.heroButton {
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: $action-blue;
  min-height: 32px;
  text-transform: uppercase;
  color: $white;
  font-weight: 700;
  font-size: 12px;
  transition: all ease-out 0.3s;
  border: solid 1px $action-blue;
  box-sizing: border-box;
  letter-spacing: 0.3px;

  &:hover {
    cursor: pointer;
    background-color: $navy;

    .arrowRight {
      left: 7px;
    }
  }
  &__active {
    background-color: $navy;
    &:hover {
      cursor: auto;
    }
  }
}
.bellIcon {
  color: $secondary-color-dark;
  position: relative;
  right: 2px;
  height: 24px !important;
  width: 24px !important;

  &__container {
    position: relative;
    width: 24px;
    height: 26.3px;
  }
  &__number {
    color: $black;
    position: absolute;
    top: 7px;
    left: 7px;
    font-size: 10px;
    font-weight: 600;
  }
}
.arrowRight {
  color: $white;
  width: 15px;
  position: relative;
  margin-left: 10px;
  transition: all ease-out 0.3s;
  left: 0;
}
