@import './vars.module.scss';

.dropZone {
  padding: 14px 16px;
  display: flex;
  justify-content: flex-start;
  flex-direction: column;
  align-items: flex-start;
  width: 100%;
  box-sizing: border-box;
  border: dashed 1px $medium-gray;
  &:hover {
    background-color: $light-gray;
    opacity: 0.5;
    cursor: pointer;
  }
}
.dropZoneDisabled {
  opacity: 0.5;
  &:hover {
    background-color: white;
    cursor: default;
  }
}
.list {
  padding: 0;
}