@import './vars.module.scss';

.container {
  display: grid;
  grid-gap: 16px;
  grid-template-columns: 1fr;
  padding-top: 31px;
  max-width: 386px;
  margin: 0 auto;
}
.card {
  box-sizing: border-box;
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 19px 14px;
  background-color: $white;
  border: solid 1px $mid-light-gray;
  min-height: 136px;

  &:hover {
    cursor: pointer;
    border-color: $action-blue;
    h5 {
      color: $action-blue !important;
    }
    
  }
}
.subIndustriesContainer {
  margin-top: 24px;
}
.subIndustryCard {
  box-sizing: border-box;
  padding: 12px 14px;
  margin-bottom: 12px;
  background-color: $white;
  border: solid 1px $mid-light-gray;
  min-height: 24px;
  display: flex;

  &:hover {
    cursor: pointer;
    border-color: $action-blue;
    h5 {
      color: $action-blue !important;
    }
  }
  &:active {
    transform: scale(0.99);
    h3 {
      color: lighten($dark-gray, 8%);
    }
  }
  &.disabled {
    h3 {
      color: lighten($dark-gray, 12%);
    }
    &:hover {
      color: lighten($dark-gray, 12%);
      border: solid 1px $mid-light-gray;
      cursor: auto;
    }
    &:active {
      transform: none;
    }
  }
}